
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AgreeToTermsPayload": [
      "AgreeToTermsSuccessPayload"
    ],
    "AnswerQuestionMessagePayload": [
      "AnswerQuestionMessageSuccessPayload"
    ],
    "ApplySeminarMutationPayload": [
      "ApplySeminarMutationErrorDuplicateEmailPayload",
      "ApplySeminarMutationErrorFullPayload",
      "ApplySeminarMutationErrorPastDuePayload",
      "ApplySeminarMutationSuccessPayload"
    ],
    "AvailableBookingRequestTImeSlotsByBookingRequestIdPayload": [
      "AvailableBookingRequestTImeSlotsByBookingRequestIdErrorNotAvailable",
      "AvailableBookingRequestTImeSlotsByBookingRequestIdSuccess"
    ],
    "AvailableTimeSlotsByOnetimeTokenPayload": [
      "AvailableTimeSlotsByOnetimeTokenErrorNotAvailable",
      "AvailableTimeSlotsByOnetimeTokenSuccess"
    ],
    "BaseError": [
      "ApplySeminarMutationErrorDuplicateEmailPayload",
      "ApplySeminarMutationErrorFullPayload",
      "ApplySeminarMutationErrorPastDuePayload"
    ],
    "CreateKollegeUserProfileWithTrialBookingResponse": [
      "CreateKollegeUserProfileWithTrialBookingFailedResponse",
      "CreateKollegeUserProfileWithTrialBookingSuccessResponse"
    ],
    "CreateKollegeUserResponse": [
      "CreateKollegeUserFailedResponse",
      "CreateKollegeUserSuccessResponse"
    ],
    "Error": [
      "UnknownError"
    ],
    "IDiagnosisResult": [
      "DiagnosisResult",
      "SharedDiagnosisResult"
    ],
    "IDiagnosisResultField": [
      "DiagnosisResultFieldWithRank",
      "DiagnosisResultFieldWithoutRank"
    ],
    "InsuranceLifeProductPlan": [
      "InsuranceLifeIncomeProtectionPlan",
      "InsuranceLifeTermOrWholeLifePlan"
    ],
    "InsuranceProductHoshoNaiyouShosaiValueType": [
      "InsuranceProductHoshoNaiyouShosaiValueTypeAmount",
      "InsuranceProductHoshoNaiyouShosaiValueTypeText"
    ],
    "InsuranceProductPlanSearchCondition": [
      "InsuranceCancerProductPlanSearchCondition",
      "InsuranceLifeProductPlanSearchCondition",
      "InsuranceMedicalProductPlanSearchCondition"
    ],
    "InsuranceProductPlanSearchConditions": [
      "InsuranceCancerProductPlanSearchConditions",
      "InsuranceLifeProductPlanSearchConditions",
      "InsuranceMedicalProductPlanSearchConditions"
    ],
    "InsuranceRankingPlan": [
      "InsuranceCancerPlan",
      "InsuranceLifeIncomeProtectionPlan",
      "InsuranceLifeTermOrWholeLifePlan",
      "InsuranceMedicalPlan",
      "InsuranceRankingPlanForRankingPayload"
    ],
    "KollegeUserCourse": [
      "KollegeUserCourse_StandardV20250418"
    ],
    "LoginPayload": [
      "AgreementToTermsRequiredPayload",
      "LoginSessionTokenPayload"
    ],
    "MessageBody": [
      "AnswerMessageBody",
      "NormalMessageBody",
      "QuestionMessageBody",
      "ReviewRequestMessageBody"
    ],
    "ReserveBookingRequestTimeSlotPayload": [
      "ReserveBookingRequestTimeSlotErrorAlreadyReserved",
      "ReserveBookingRequestTimeSlotErrorInvalidTimeSlot",
      "ReserveBookingRequestTimeSlotErrorNotAvailable",
      "ReserveBookingRequestTimeSlotSuccess"
    ],
    "SimpleLifePlanningLifeEvent": [
      "EducationEvent",
      "UserEvent"
    ],
    "ViewerMutationPayload": [
      "ViewerMutationErrorPayload",
      "ViewerMutationSuccessPayload"
    ],
    "ViewerQueryPayload": [
      "ViewerQueryErrorPayload",
      "ViewerQuerySuccessPayload"
    ]
  }
};
      export default result;
    